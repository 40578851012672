.app-header {
  @include basic-border(0,0,1px,0);
  background: $white;
  padding: 1rem .5rem;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 20;

  @media (min-width: $mobile-width) {
    height: $main-nav-height;
  }
}

.main-nav {
  display: flex;
  > * {
    flex-grow: 1;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    color: #737373;
  }
}

.main-nav__title {
  flex: 0 0 100px;
  display: inline-block;
  padding-left: .5rem;
  img {
    height: 20px;
  }
}

.main-nav__account-nav {
  text-align: right;
  .material-symbols-outlined {
    margin-top: -3px;
    margin-left: .25rem;
    vertical-align: top;
  }

  a {
    cursor: pointer;
  }
}

@media (min-width: $mobile-width) {
  .main-nav__account-nav,
  .main-nav__organization-nav {
    text-align: right;
    margin-left: 1rem;
    display: inline-block;
  }
}
