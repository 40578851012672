.organization-comparison {
  white-space: nowrap;
  background-color: $organization-comparison-bg-color;
  overflow: scroll;
  width: 100vw;
  min-height: calc(100vh - #{$main-nav-height});


  @media (min-width: $mobile-width) {
    margin-left: $dataset-column-width-default;
    width: calc(100vw - #{$dataset-column-width-default});
    padding-left: 1rem;

    &.open-panel {
      transition: width $transition-speed;
      width: calc(100vw - #{$detail-panel-width});

      @media (min-width: $mobile-width) {
        width: calc(100vw - (#{$large-detail-panel-width} + #{$dataset-column-width-default}));
      }
    }
  }
}


.multi-column {
  width: $dataset-column-width-default;
  display: inline-block;
  margin-right: 1rem;
}

.one-column {
  width: 100%;
  margin-right: 0;
}

.comparison-nav__home {
  @include basic-shadow;
  @include basic-border(0,0,1px,0);
  background-color: $white;
  height: $subnav-height;
  text-align: center;
  position: fixed;
  width: $comparison-sidebar-width - 1px; // remove 1px because of border
  z-index: 10;

  @media (min-width: $mobile-width) {
    width: $dataset-column-width-default - 1px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  .material-symbols-outlined {
    line-height: $subnav-height;
    opacity: 0.55;
    cursor: pointer;
  }
}

.comparison-nav__home-link {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  height: 50px;
  transition: opacity, .25s;
  &:hover {
    background-color: rgba(0,0,0,.025);
  }
}

.comparison-nav__home-text {
  display: inline-block;
  padding-top: 1em;
  vertical-align: top;
}

.comparison-nav {
  @include basic-shadow;
  @include basic-border(0,0,1px,0);
  display: flex;
  position: fixed;
  z-index: 10;
  height: $subnav-height;
  line-height: $subnav-height;
  padding: 0 .25rem;
  background-color: $organization-comparison-nav-bg-color;
  color: $bluesteel;
  text-transform: uppercase;
  width: 100%;

  &.with-comparison {
    float: right;
    transition: all $transition-speed;
    margin-left: 100px;
    width: calc(100vw - 100px);
  }

  .material-symbols-outlined {
    font-size: 30px;
    line-height: $subnav-height;
  }
}

.comparison-nav > * {
  flex-grow: 1;
}

.comparison-nav__previous {
  text-align: left;
}

.comparison-nav__current-org {
  text-align: center;
}

.comparison-nav__next {
  text-align: right;
}

.organization-dataset__header {
  font-size: 18px;
  font-weight: 200;
  text-align: center;
}

.organization-dataset {
  display: inline-block;
  position: relative;
}

.organization-dataset__wrapper {
  margin: 0 5px 0;

  @media (max-width: $mobile-width) {
    margin: 65px 5px 0;
  }
}

.organization-comparison .react-swipe-container {
  &.with-comparison {
    // This saves space for the current-org sidebar
    margin-left: $comparison-sidebar-width;
    width: calc(100vw - #{$comparison-sidebar-width});
  }
}

.comparison-dataset__current-org {
  @include basic-border(0,1px,0,0);
  width: $comparison-sidebar-width;
  background-color: $white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  @media (min-width: $mobile-width) {
    width: 250px;
  }
}

.comparison-dataset__wrapper {
  margin-top: 60px;
  @media (max-width: $mobile-width) {
    margin-top: calc(#{$subnav-height} + 25px);
  }
}

.comparison-dataset__card {
  padding: .5rem;
  height: 200px;
  text-align: center;
  border-bottom: 1px solid $border-color;

  .trend {
    @include small-text;
    display: inline-block;
    margin-top: 15px;
  }
}

.current-org-enter {
  left: -$comparison-sidebar-width;
  transition: all $transition-speed;

  &.current-org-enter-active {
    transform: translateX($comparison-sidebar-width);
  }
}

.current-org-leave {
  transition: all $transition-speed;

  &.current-org-leave-active {
    transform: translateX(-$comparison-sidebar-width);
  }
}

.comparison-dataset__card-header {
  @include small-text;
  margin-top: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
}

.comparison-dataset__card-kpi {
  font-size: 22px;
}

.comparison-dataset__card-sample-size {
  margin-top: 15px;
}
