a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.button {
  @include basic-border;
  @include rounded-corners;
  padding: .5rem 1rem;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-color: $light-gray;
  text-decoration: none;
  color: $text-color;
  text-transform: uppercase;

  &:focus {
    outline: 0;
  }
}

.button--primary {
  @extend .button;
  border: none;
  background-color: $dips-blue;
  color: white;
}
