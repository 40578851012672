.admin-nav {
  display: flex;
  padding: 1rem 0;
  justify-content: space-between;
  max-width: 400px;
}

.admin-nav__pill {
  background: $light-gray;
  color: $text-color;
  padding: .5rem 1rem;
  border-radius: 1rem;

  &:hover {
    text-decoration: none;
  }
}

.admin-nav__pill--active {
  background: $dips-blue;
  color: white;
}
