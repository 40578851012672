.detail-panel {
  position: fixed;
  top: 0;
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
  background-color: $panel-bg-color;
  z-index: 30;
  @media (min-width: $mobile-width) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: $detail-panel-width;
    box-shadow: -2px 0px 40px rgba(0, 0, 0, 0.50);

    @media (min-width: 750px) {
      width: $large-detail-panel-width;
    }

    &.menu-panel {
      width: $menu-panel-width;
    }
  }

  h4 .material-symbols-outlined {
    margin-left: 0.25em;
    vertical-align: middle;
  }
  h4 {
    margin-bottom: -8px;
  }
}

.panel-enter {
  right: -100%;
  z-index: 50;
  transition: transform $transition-speed;

  &.panel-enter-active {
    transform: translateX(-100%);
  }
}

@media (min-width: $mobile-width) {
  .panel-enter {
    right: -$detail-panel-width;

    @media (min-width: 750px) {
      right: -$large-detail-panel-width;
    }

    &.menu-panel {
      right: -$menu-panel-width;
    }
  }
}

.panel-leave {
  transition: transform $transition-speed;

  &.panel-leave-active {
    transform: translateX(100%);
  }
}

.detail-panel__header {
  background-color: $white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.detail-panel__title-bar {
  @include basic-border(0,0,1px,0);
  padding: .5rem 1rem;
  position: relative;
}

.detail-panel__close {
  position: absolute;
  top: 0.1rem;
  right: 0rem;

  button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
  }
}

.detail-panel__previous {
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 0.6rem;
  left: 0rem;
  font-size: 14px;

  .material-symbols-outlined {
    font-size: 20px;
    margin-top: -2px;
    vertical-align: top;
  }
}

.detail-panel__summary {
  padding: .5rem 1rem;

  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 0;
  }

  li {
    margin: 0;
    flex-grow: 1;
    padding: .5rem;
    text-align: center;
  }
}

.detail-panel__body{
  margin-top: 1rem;
  padding: .5rem 1rem;
  white-space: normal;
}

.chart-comparison-type {
  margin-left: 0.5rem;
}

.detail-panel__tabs {
  text-transform: uppercase;
  padding: .5rem;
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    margin: 0;
  }
  li {
    @include basic-border(1px,1px,1px,0);
    margin: 0;
    flex-grow: 1;
    padding: .5rem;
    text-align: center;
    background-color: #5D676D;
    color: $white;
    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border-left-width: 1px;
    }
    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
    &.tab-active {
      color: #5D676D;
      background-color: $light-gray;
    }
  }
}

.detail-panel__buttons {
  margin: 30px 15px;
}

.detail-panel__logout, .detail-panel__admin {
  margin: 10px 0;

  button, a {
    @include basic-border;
    color: $text-color;
    cursor: pointer;
    border: 1px solid $border-color;
    display: inline-block;
    font-size: 0.7em;
    padding: 7px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%
  }
}

.detail-panel__bread-crumbs {
  background: $white;
  color: #848484;
  list-style: none;
  font-size: 0.8em;
  padding: 8px;
  margin: 0;
}

.detail-panel__bread-crumb {
  display: inline;
  cursor: pointer;
  text-transform: uppercase;

  &.active {
    font-weight: bold;
    cursor: default;
  }

  a {
    color: #848484;
    text-decoration: none;
  }

  i {
    font-size: 10px;
    margin-right: 5px;
  }

  + .detail-panel__bread-crumb {
    margin-left: 5px;
  }
}

.detail-panel__label {
  margin-left: 1rem;
}

.kpiChart__data-updated-date {
  @include small-text;
}
