@mixin small-text {
  font-size: 12px;
}

@mixin basic-shadow {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.125);
}

@mixin basic-border($border-top-width: 1px, $border-right-width: 1px, $border-bottom-width: 1px, $border-left-width: 1px) {
  border-top-width: $border-top-width;
  border-right-width: $border-right-width;
  border-bottom-width: $border-bottom-width;
  border-left-width: $border-left-width;
  border-style: solid;
  border-color: rgba(0,0,0,.15);
}

@mixin rounded-corners {
  border-radius: 2px;
}
