.devise {
  @include basic-border;
  @include basic-shadow;
  background-color: $white;
  margin: auto;
  width: 95vw;
  max-width: 400px;
  margin-top: 2rem;
  padding: 1rem;
  .button, .button--primary {
    display: block;
    margin-bottom: 1rem;
    font-size: 14px;
  }
}

.sign-up-or-login {
  @extend .devise;
  padding: 20px 50px;
}

.devise-shared-links {
  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    margin-bottom: .25rem;
  }
  a {
    color: $dips-blue;
  }
}
