table {
  width: 100%;
}

td {
  border-top: 1px solid $dark-gray;
  border-right: 1px solid $dark-gray;
  padding: 0.5em;

  &:last-child {
    border-right: none;
  }
}
