.alert__container {
  position: fixed;
  top: 35px;
  width: 100%;
  z-index: 40;
}

.alert {
  @include basic-border;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-color: $alert-bg;
  padding: .5rem 40px .5rem .5rem;
  position: relative;
  font-size: 14px;
  margin: 0 auto;
  max-width: 90%;

  @media (min-width: $mobile-width) {
    max-width: 700px;
  }
}

.alert__close {
  position: absolute;
  top: 0rem;
  right: 0rem;
  padding: .25rem .5rem;
  cursor: pointer;
}
