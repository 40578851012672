$mobile-width: 600px;

// Base colors
$text-color: #555555;
$light-text: #939393;
$border-color: #E0E0E0;
$white: #ffffff;
$bluesteel: #5C6B71;
$light-gray: #FBFBFB;
$dark-gray: lighten($text-color, 20%);
$dips-blue: #4A9AB0;
$light-yellow: #FEF4E0;

// Special colors
$kpi-card-bg-color: $light-gray;
$kpi-card-warning-text-color: #7F0000;
$kpi-card-on-target-text-color: #007F24;
$kpi-card-neutral-text-color: #7A7A7A;
$kpi-card-orange-text-color: orange;


$alert-bg: $light-yellow;

$organization-comparison-bg-color: #E3E8E9;
$organization-comparison-nav-bg-color: #F2F5F7;
$panel-bg-color: #EFF2F3;

// Special dimensions
$dataset-column-width-default: 250px;
$comparison-sidebar-width: 100px;
$main-nav-height: 50px;
$subnav-height: 50px;
$menu-panel-width: 300px;
$detail-panel-width: 300px;
$large-detail-panel-width: 40%;
$kpi-card-height: 185px;

// animations
$transition-speed: 0.3s;
