// Look up icons at: https://fonts.google.com/icons?icon.set=Material+Symbols
// Instead of hosting the font files locally which requires the compiler to handle them, we pull the icons
// from Googles CDN like we do for our other fonts
// https://developers.google.com/fonts/docs/material_symbols#variable_font_with_google_fonts

@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200';

.material-symbols-outlined,
.material-symbols {
  --op-mso-fill: 0;
  --op-mso-weight: 400;
  --op-mso-grade: 0;
  --op-mso-optical-sizing: 20;

  font-size: 24px;
  font-variation-settings: 'FILL' var(--op-mso-fill), 'wght' var(--op-mso-weight), 'GRAD' var(--op-mso-grade),
    'opsz' var(--op-mso-optical-sizing);
  line-height: 1;
  vertical-align: middle;
}
