.organization-menu,
.focus-area-menu {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.organization-menu__list,
.focus-area-menu__list {
  border-bottom: 1px solid $border-color;
  li {
    padding-left: 0;
    margin-left: 0;
  }

  $org-selector: ul a;
  @for $i from 0 through 10 {
    #{$org-selector} {
      padding-left: calc(35px + (20px * #{$i}))
    }
    $org-selector: ul #{$org-selector}
  }
}

.organization-menu__header,
.focus-area-menu__header {
  color: #a7a7a7;
  font-size: 0.7em;
  margin: 20px 0 5px;
  padding-left: 15px;
  text-transform: uppercase;
}

.organization-menu__item,
.focus-area-menu__item {
  a {
    background: $white;
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9em;
    text-decoration: none;
    padding: 8px 15px;
    width: 100%;
    border-top: 1px solid $border-color;

    &.full {
      border-top: 1px solid $border-color;
      border-radius: 0;

    }

    &:hover {
      background: darken($white, 10%)
    }

    &.disabled {
      background-color: $light-gray;
      color: $light-text;
      cursor: default;
    }
  }

  .right-icon {
    float: right;
    font-size: 16px;
  }

  .nested {
    font-size: 16px;
  }
}

.organization-menu__footnote {
  @include small-text;
  color: $light-text;
  padding: 10px 15px;
}
