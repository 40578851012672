.form-group {
  margin-bottom: 1rem;
}

.form-control {
  @include basic-border;
  @include rounded-corners;
  display: block;
  width: 100%;
  padding: .5rem;
}

select {
  height: 2rem;
}

label {
  display: inline-block;
  margin-bottom: .25rem;
}

label {
  [type="checkbox"],
  [type="radio"],
  &.checkbox,
  &.radio {
    margin-right: .5rem;
    vertical-align: middle;
    margin-top: -2px;
  }
}
