%kpi-card-base-styles {
  @include basic-shadow;
  @include basic-border;
  cursor: pointer;
  text-align: center;
  height: $kpi-card-height;
  margin: 1em auto;
  padding: 1rem .5rem;
  position: relative;
  white-space: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: $kpi-card-bg-color;
  border-radius: 0.25rem;
  &:hover {
    background-color: white;
    transition: background-color, .25s;
  }

  &.kpi-card__root-org {
    border: none;
    background: white;
    box-shadow: none;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
    padding: 1rem .5rem;
  }
}

.kpi-card {
  @extend %kpi-card-base-styles;
}

.kpi-card--disabled {
  @extend %kpi-card-base-styles;
}

.kpi-card--private-hidden {
  @extend %kpi-card-base-styles;
  opacity: 0.7;
}

.kpi-card--hidden {
  @extend %kpi-card-base-styles;
  opacity: 0.7;
  border: 1.5px dashed $dark-gray;
  box-shadow: 0 0 0 rgba(0,0,0,0);
  background-color: transparent;
  cursor: default;
  &:hover {
    background-color: transparent;
  }
}

.kpi-card__header {
  padding: 5px;
  min-height: 50px;
}

.kpi-card__body {

}

.kpi-card__sub-header {
  @include small-text;
  padding-top: 5px;
  color: $dark-gray;
  text-transform: uppercase;
}

.kpi-card__sub-header-icon {
  font-size: 18px;
  display: inline-flex;
  vertical-align: middle;
  margin-top: -2px;
}

.kpi-card__unavailable-label {
  text-transform: uppercase;
}

.kpi-card__unavailable-value-icon {
  font-size: 36px;
  opacity: .7;
}

.kpi-card__unavailable-label {
  @include small-text;
  opacity: .7;
}

.kpi-card__unavailable-explanation {
  @include small-text;
}

.kpi-card__kpi-value {
  font-size: 48px;
  font-weight: 600;
}

.kpi-card__value-negative {
  color: $kpi-card-warning-text-color;
}

.kpi-card__value-positive {
  color: $kpi-card-on-target-text-color;
}

.kpi-card__value-neutral {
  color: $kpi-card-neutral-text-color;
}

.kpi-card__value-orange {
  color: $kpi-card-orange-text-color;
}

.kpi-card__footer {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.kpi-card__trend {
  text-align: left;
  width: 50px;
}

.kpi-card__comments {
  margin: 0 auto;
  .material-symbols-outlined {
    font-size: 18px;
  }
}

.kpi-card__sample-size {
  @include small-text;
  text-align: right;
  width: 50px;
}


.kpi-card__root-org {
  .kpi-card__header {
    margin-top: -10px;
  }
  @media (max-width: $mobile-width) {
    .kpi-card__header {
      @include small-text;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80px;
      padding: 0 5px;
      min-height: 35px;
      margin-bottom: 15px;
      margin-top: 0;
    }

    .kpi-card__kpi-value {
      font-size: 22px;
    }
    .kpi-card__footer {
      padding: 15px;
      display: block;
    }
    .kpi-card__trend {
      width: auto;
      text-align: center;
    }

    .kpi-card__comments {
      text-align: center;
      .material-symbols-outlined {
        font-size: 16px;
      }
    }

    .kpi-card__sample-size {
      @include small-text;
      text-align: center;
      width: auto;
    }
  }
}
