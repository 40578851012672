* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: $organization-comparison-bg-color;
  font-family: 'helvetica neue', 'helvetica', arial, sans-serif;
  color: $text-color;
}

.container {
  margin-top: $main-nav-height;
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  min-height: calc(100vh - #{$main-nav-height});
  background-color: $organization-comparison-bg-color;

.admin-container {
    margin: 0 auto;
    max-width: 1000px;
  }
}

.center {
  text-align: center;
  padding: 0 1em;
}
